<template>
    <defaultSec :title="'SOP推送列表'" class="route-customer-wrap">
        <el-form :inline="true" class="demo-form-inline select-wrap" size="mini">
            <el-form-item label="日期">
                <el-date-picker
                        v-model="formParam.createDate"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="群组/备注/昵称">
                <el-input type="text" v-model="formParam.searchText" placeholder="输入群组/备注/昵称查询 "></el-input>
            </el-form-item>
            <el-form-item label="openId">
                <el-input type="text" v-model="formParam.openId" placeholder="输入openId查询 "></el-input>
            </el-form-item>
            <el-form-item label="选择企微" v-if="formParam.all==1">
                <el-select clearable :popper-append-to-body="false"  filterable size="mini" value-key="id"
                           v-model="formParam.qwUserId">
                    <el-option v-for="user in userList" :label="user.name" :value="user.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="执行状态">
                <el-select v-model="formParam.status" placeholder="请选择">
                    <el-option label="全部" :value="-5"></el-option>
                    <el-option label="待处理" :value="0"></el-option>
                    <el-option label="已处理" :value="1"></el-option>
                    <el-option label="已发送" :value="2"></el-option>
                    <el-option label="已回复" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="素材组">
                <el-select clearable :popper-append-to-body="false" size="mini" value-key="id"
                           v-model="formParam.groupId">
                    <el-option v-for="user in groupList" :label="user.groupName" :value="user.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="推送类型">
                <el-select v-model="formParam.pushType" placeholder="请选择">
                    <el-option label="手动推送" :value="1"></el-option>
                    <el-option label="自动推送" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="initList">查询</el-button>
                <el-button type="success" size="mini" @click="showSop()">sop继承</el-button>
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="tableList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column label="优先级" width="85">
                    <template slot-scope="scope">
                        <el-tag type="danger" size="mini" v-if="scope.row.priorityLevel==1">优先级高</el-tag>
                        <el-tag type="warning" size="mini" v-if="scope.row.priorityLevel==2">优先级中</el-tag>
                        <el-tag type="success" size="mini" v-if="scope.row.priorityLevel==3">普通</el-tag>
                    </template>
                </el-table-column>
                <el-table-column width="210" prop="routeName" label="群组信息">
                    <template slot-scope="scope">
                        <div style="font-size: 13px;font-weight: 600;">{{scope.row.sceneName}}</div>
                        <div style="font-size: 10px;font-style: italic">
                            <i style="color: #2dacd1" class="el-icon-s-order"></i>备注:{{scope.row.sceneRemark}}
                        </div>
                        <div style="font-size: 10px;font-style: italic">
                            <i style="color: #55a532" class="el-icon-s-promotion"></i>来自旅程:{{scope.row.routeName}}
                        </div>
                        <!--                      <div style="font-size: 10px;font-style: italic">-->
                        <!--                        <i style="color: #55a532" class="el-icon-user-solid"></i>所属企微:{{scope.row.qyWeixinUserName||scope.row.qyWeixinUserid}}-->
                        <!--                      </div>-->
                        <div style="font-size: 10px;font-style: italic" v-if="scope.row.remark">
                            <i style="color: #55a532" class="el-icon-paperclip"></i>{{scope.row.remark}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="qyWeixinUserid" label="所属企微" minWidth="100">
                    <template slot-scope="scope">
                        <el-badge type="warning" :value="scope.row.holiday" style="margin-top: 10px;font-size: 10px">
                            <div style="padding: 0 6px">{{scope.row.qyWeixinUserName || scope.row.qyWeixinUserid}}</div>
                        </el-badge>


<!--                        <el-tag v-if="scope.row.holiday" size="mini">{{scope.row.holiday}}</el-tag>-->
                    </template>
                </el-table-column>
                <el-table-column width="280" prop="nickName" label="用户信息 (可进入用户详情)">
                    <template slot-scope="scope">
                        <div @click="toDetail(scope.row.customerId)">
                            <p class="user-info" style="text-align: center">
                                <el-avatar icon="el-icon-user-solid" :size="30" :src="scope.row.avatar"></el-avatar>
                            </p>
                            <el-link class="user-info"><span
                                    style="color: #55a532">● </span>昵称：{{scope.row.nickName || '微信用户'}}
                            </el-link>
                            <br/>
                            <el-link class="user-info"><span style="color: #55a532">● </span>手机号：{{scope.row.phone}}
                            </el-link>
                        </div>
                        <div class="copy-text" v-if="scope.row.orderMainNo">
                            <div><span style="color: #e50011">● </span>主单号：{{scope.row.orderMainNo}}</div>
                            <div><span style="color: #e50011">● </span>子单号：{{scope.row.orderNo}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="pushText" label="推送文案/素材" width="320">
                    <template slot-scope="scope">
                        <div v-if="scope.row.pushText && scope.row.materialType == 'text'"
                             style="position: relative;padding-top: 20px;">
                            <el-button @click="copyText(scope.row.pushText)" style="position: absolute;top: 0;right: 0"
                                       size="mini" type="text" icon="el-icon-document-copy">复制
                            </el-button>
                            <span style="font-weight: 600;margin: 5px 0">文案编号:{{scope.row.materialCode}}&nbsp;&nbsp;{{scope.row.materialParentCode ? '前置文案:' + scope.row.materialParentCode : ''}}</span>
                            <p class="copy-text" slot="reference" v-html="scope.row.pushText"></p>
                        </div>
                        <div class="chat-content chat-image" v-if="scope.row.mediaType">
                            <!--  v-if="formParam.materialType == 'media'" TODO 图片预览 v-if="scope.row.mediaType='image'" -->
                            <el-image v-if="scope.row.mediaType == 'image'" fit="cover"
                                      style="width: auto;height:180px;display: block" :src="scope.row.ossUrl"
                                      :preview-src-list="[scope.row.ossUrl]"></el-image>
                            <!-- TODO 视频预览 v-if="scope.row.mediaType='video'" -->
                            <p v-if="scope.row.mediaType!='image'">视频语音文件暂不支持预览</p>
                            <!-- TODO 语音预览 v-if="scope.row.mediaType='voice'" -->

                            <!-- TODO 文件预览 v-if="scope.row.mediaType='file'" -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="keyword" label="非标文案" width="300">
                    <template slot-scope="scope">
                        <div v-if="scope.row.keyword && scope.row.materialType == 'text'" style="position: relative;">
                            <p class="copy-text" slot="reference" v-html="scope.row.keyword"></p>
                        </div>
                        <div v-if="scope.row.materialType != 'text'" style="position: relative;">
                            {{scope.row.mediaType}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="kefuUseStatus" label="状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="danger" v-if="scope.row.kefuUseStatus==0">待处理</el-tag>
                        <el-tag size="mini" type="success" v-if="scope.row.kefuUseStatus==1">已处理</el-tag>
                        <el-tag size="mini" type="warning" v-if="scope.row.kefuUseStatus==2">已发送</el-tag>
                        <el-tag size="mini" style="color:#67C23A" v-if="scope.row.kefuUseStatus==3">已回复</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="pushType" label="推送类型">
                    <template slot-scope="scope">
                        <div>
                            <el-tooltip class="item" effect="dark" content="自动推送官方推送次数限制，可手动推送" v-show="false" placement="right">
                                <el-tag size="mini" type="info" v-if="scope.row.pushType==1">手动推送</el-tag>
                                <el-tag size="mini" type="success" v-if="scope.row.pushType==2">自动推送</el-tag>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="kefuUseTime" label="处理时间"></el-table-column>
                <el-table-column fixed="right" width="140" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text"
                                   v-if="scope.row.kefuUseStatus==0&&((scope.row.parentUseStatus==3&&scope.row.materialParentCode!=null)||(scope.row.materialParentCode==null))"
                                   size="mini" @click="copyPhone(scope.row)">发送文案
                        </el-button>
                        <el-button size="mini" @click="onChat(scope.row)" type="text">打开聊天</el-button>
                        <el-button style="margin-left: 0" size="mini" @click="showExample(scope.row)" type="text">成功案例</el-button>
                        <el-button size="mini" @click="showSopHistory(scope.row)" type="text">推送日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
        <exampleDialog ref="exampledialog"></exampleDialog>
        <sopHistory ref="sopHistory"></sopHistory>
        <stream ref="stream" @initList="initList"></stream>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import exampleDialog from "@c/scrm/exampleDialog";
    import sopHistory from "@c/scrm/sopHistory";
    import stream from "@c/scrm/stream";
    import {wxQw} from "@/api/wxQwConfig";
    let wx = window.wx
    let qwPlatform = navigator.userAgent.indexOf('wxwork') > -1 ? true : false
    export default {
        data() {
            return {
                total: 0,
                tableList: [],
                routeList: [],
                showSopInherit: false,
                formParam: {
                    pageSize: 20,
                    currentPage: 1,
                    createDate: new Date().Format("yyyy-MM-dd"),
                    status: -5,
                    all: this.$route.query.all,
                    qwUserId: '',
                    groupId: '',
                    openId:'',
                    pushType: 1
                },
                configInitStatus: 1,
                userList: [],
                groupList: [],
            }
        },
        components: {
            defaultSec, tablePagination, exampleDialog, stream,sopHistory
        },
        beforeCreate() {

        },
        mounted(){
            //企微打开:config
            wxQw()
            this.initRouteOption();
            this.initList();
            this.initUserList();
            this.initgroupList();
        },
        methods: {
            initList() {
                var userAgent = window.navigator.userAgent;
                this.formParam.all = userAgent.indexOf("wxwork") > 0 ? 0 : 1;

                this.$apiGet('lechun-cms/scrmRoute/getQwServiceList', this.formParam).then(res => {
                    this.total = res.total;
                    this.tableList = res.list;
                })
            },
            //企微客服列表
            initUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.userList = res
                })
            },
            //素材分组列表
            initgroupList(){
                this.$apiGet('lechun-cms/sceneRouteGroup/getGroupList', {
                    currentPage: 1,
                    pageSize: 10000,
                    sceneRoute: 3
                }).then(res => {
                    console.log(res)
                    this.groupList = res.list
                })
            },
            initRouteOption(){
                this.$apiGet('lechun-cms/scrmRoute/getServiceOption', {}).then(res => {
                    this.routeList = res;
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            copyText(text){
                var input = document.createElement("textarea"); // 创建input对象
                input.value = text; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message({
                    type: 'success',
                    message: '已复制到剪贴板'
                });
            },
            copyPhone(row){
                if (!qwPlatform) {
                    this.$message({
                        message: '浏览器不支持打开企微对话,请在企业微信打开',
                        type: 'warning'
                    });
                    return;
                }
                this.copyText(row.pushText);
                this.$apiGet('lechun-cms/scrmRoute/setProcessStatus', {'id': row.id}).then(res => {
                    console.log("res---", res)
                    row.kefuUseStatus = 1;
                    this.onChat(row);
                    this.initList()
                })
            },
            onChat(row){
                if (!qwPlatform) {
                    this.$message({
                        message: '浏览器不支持打开企微对话,请在企业微信打开',
                        type: 'warning'
                    });
                    return;
                }
                wx.openEnterpriseChat({
                    // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
                    externalUserIds: row.externalUserid, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
                    groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
                    chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
                    success: function (res) {
                        console.log(res)
                    },
                    fail: function (res) {
                        console.log(res);
                        if (res.errMsg.indexOf('function not exist') > -1) {
                            alert('版本过低请升级')
                        }
                    }
                });
            },
            toDetail(id){
                let url = window.location.origin + '/userDetail/' + id
                window.open(url, '_blank')
            },
            getConfigInfo()    {
                let appSignature; // 通过接口，获取agentConfig的参数
                const urlNow = encodeURIComponent(window.location.href);
                console.log('当前授权URL：', urlNow);
                const noncestr = Math.floor(Math.random() * 100000000000000);
                let body = {
                    url: urlNow,
                    timestamp: 1000,
                    nonceStr: noncestr,
                    type: "agent_config"
                };
                let that = this;
                this.$apiGet('lechun-cms/distributor/getQwConfig', body).then(res => {
                    appSignature = res;
                    console.log(appSignature);
                    console.log('wx.agentConfig:BEGIN');
                    wx.agentConfig({
                        corpid: appSignature.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: appSignature.agentid, // 必填，企业微信的应用id （e.g. 1000247）生产环境
                        timestamp: appSignature.timestamp, // 必填，生成签名的时间戳
                        nonceStr: appSignature.nonceStr, // 必填，生成签名的随机串
                        signature: appSignature.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: [
                            'openUserProfile',
                            'previewFile',
                            'getCurExternalContact',
                            'openEnterpriseChat'
                        ], // 必填
                        debug: 1,
                        success(res) {
                            console.log(res)
                        },
                        fail(res) {
                            console.log('err', res);
                            that.configInitStatus = 0;
                            if (res.errMsg.indexOf('function not exist') > -1) {
                                alert('版本过低请升级');
                            }
                        }
                    });
                })
            },
            /*展示sop继承*/
            showSop(){
                this.$refs.stream.initUserList()
                this.$refs.stream.showSop()
            },
            /*展示成功案例*/
            showExample(e){
                this.$refs.exampledialog.getExampleList(e.materialId)
            },
            /*展示SOP推送历史*/
            showSopHistory(e){
                this.$refs.sopHistory.getSopHistoryList(e.customerId)
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .route-customer-wrap {
        .user-info {
            margin: 0;
            cursor: pointer;
            font-size: 12px;
            font-weight: normal;
        }
        .copy-text {
            white-space: pre-line;
            margin: 0;
            line-height: 18px;
            background: #f2f2f2;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            padding: 6px;
            max-height: 130px;
            overflow-y: scroll;
            scrollbar-width: none;
        }
    }

</style>
