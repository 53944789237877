<template>
    <el-drawer :visible.sync="showSopDialog" title="sop继承" size="30%">
        <div>
            <el-descriptions  border title="">
                <el-descriptions-item label="选择接收的企微">在企微操作离职/在职继承后,选择接收人和日期 保存后sop自动被继承 </el-descriptions-item>
            </el-descriptions>
            <el-form :model="form" label-width="120px" size="small">
                <el-form-item label="请选择企微">
                    <el-select :popper-append-to-body="true" filterable multiple clearable v-model="form.to">
                        <el-option
                                v-for="item in toQiWeiList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="从哪天开始">
                    <el-date-picker
                            v-model="form.date"
                            type="date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="sopInherit()">保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>

<script>
    export default {
        name: "sop继承",
        data(){
            return {
                showSopDialog: false,
                toQiWeiList: [],
                form: {
                    to:[],
                    date: new Date().Format("yyyy-MM-dd")
                }
            }
        },
        mounted(){
            this.initUserList()
        },
        methods: {
            showSop(){
                this.showSopDialog = true
            },
            initUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.toQiWeiList = res
                })
            },
            sopInherit(){
                if (this.form.date == '') {
                    this.$message({
                        type: 'error',
                        message: '请选择从哪天开始流转'
                    });
                    return;
                }
                if (this.form.to == '') {
                    this.$message({
                        type: 'error',
                        message: '请选择要流转给哪个企微'
                    });
                    return;
                }
                this.$confirm('流转不可撤销,确定流转吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiPost('lechun-cms/scrmRoute/sopInherit', this.form).then(res => {
                        console.log(res)
                        this.$message({
                            type: 'success',
                            message: '已继承' + res.count + '条记录'
                        });
                        this.$emit('initList');
                        this.showSopDialog = false
                    })
                }).catch(() => {

                });
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .example-dialog-wrap {
        .el-card__header {
            border-bottom: 1px solid #d7d7d7 !important;
        }
        .chat-item {
            display: flex;
            margin-bottom: 20px;
            font-size: 12px;
        }

        .chat-item-reverse {
            flex-direction: row-reverse;
        }
        .chat-img {
            width: 28px;
            display: block;
            margin-right: 10px;
        }
        .chat-img img {
            width: 28px !important;
        }
        .chat-item-reverse .chat-img {
            margin-left: 10px;
            margin-right: 0;
        }
        .chat-content {
            background: #f5f5f5;
            padding: 10px;
            border-radius: 5px;
            display: inline-block;
            white-space: pre-wrap;
            max-width: 80%;
        }
        .chat-item-reverse .chat-content {
            background: #a6e860;
        }
        .chat-miniprogram {
            border: 1px solid #dddddd;
            border-radius: 5px;
            padding: 10px;
            width: 200px;
        }
    }

</style>
